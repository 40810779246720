#snow-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    overflow: hidden;
}
.snowflake {
    position: absolute;
    top: -10px;
    background-color: #f2f2f2;
    border-radius: 50%;
    opacity: 0.95;
    animation: combinedMotion linear infinite; /* Single combined animation */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.5); /* Subtle glow */
}

@keyframes combinedMotion {
    0% {
        transform: translate(0, -10px);
        opacity: 0.9;
    }
    25% {
        transform: translate(10px, 25vh);
    }
    50% {
        transform: translate(-10px, 50vh);
    }
    75% {
        transform: translate(15px, 75vh);
    }
    100% {
        transform: translate(-15px, 110vh);
        opacity: 0.4;
    }
}
