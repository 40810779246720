@import url("https://fonts.googleapis.com/css?family=Montserrat:700");

.hero {
    /* background-color: #0040c1; */
    position: relative;
    height: 100%;

    /* font-family: "Montserrat", sans-serif; */
}

.hero__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    z-index: 1;
}

.cube {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;
    border: solid 1px #01b4ae;
    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: cube 12s ease-in forwards infinite;
}

.cube:nth-child(2n) {
    border-color: #ffeb88; /* Lighten color manually */
}
.cube:nth-child(3n) {
    border-color: #016b67; /* Lighten color manually */
}

.cube:nth-child(2) {
    animation-delay: 2s;
    left: 25vw;
    top: 40vh;
}

.cube:nth-child(3) {
    animation-delay: 4s;
    left: 75vw;
    top: 50vh;
}

.cube:nth-child(4) {
    animation-delay: 6s;
    left: 90vw;
    top: 10vh;
}

.cube:nth-child(5) {
    animation-delay: 8s;
    left: 10vw;
    top: 20vh;
}

.cube:nth-child(6) {
    animation-delay: 10s;
    left: 50vw;
    top: 10vh;
}

@keyframes cube {
    from {
        transform: scale(0) rotate(0deg) translate(-50%, -50%);
        opacity: 1;
    }
    to {
        transform: scale(20) rotate(960deg) translate(-50%, -50%);
        opacity: 0;
    }
}
